







export default {
  setup(props, { root: { $router } }) {
    const pushHome = () => {
      $router.push('portfolio');
    };
    return { pushHome };
  }
};
